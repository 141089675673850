import React , {useState , useEffect, setTime} from 'react';
import styles from './detail_page.module.css';
import Detail from './detail';
import Navbar from '../New_Header_Navigation/navbar';
import Footer from '../New_Footer/footer';
import DenyModal from './modal';
import { getDetail } from '../user'; 
import { investPort, divestPort, getUsers, createEmail, changeVis, requestApproval, Approved, Denied} from '../user';
import { useNavigate } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard'
import { set } from 'date-fns';
import SideMenu from './dashboard/components/SideMenu';
function DetailPage() {
  const [amount, setAmount] = useState();
    const [data, setData] = useState([]);
    const [rerender, setRerender] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);
    const [error , setError] = useState('');
    const current_page = localStorage.getItem('Current Page');
    const navigate = useNavigate();

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTime(Date.now()); // Update state to trigger re-render
    }, 60000);
      console.log("HERRO");
      async function fetchData() {
        try {
          console.log("Fetching data...");
          const user_id = localStorage.getItem('user_id');
          const port_id = localStorage.getItem('port_id');
          console.log("ID: ",user_id);
          console.log("Current Page: ",current_page);
          console.log("PORT ID: ",port_id);
  
  
          // Fetch data using the getSport function
          let raw_data = null;
          setLoading(true);
          if (current_page === 'My Ports') {
            raw_data = await getDetail('personal',port_id, user_id);
          }else if (current_page === 'Port Market' || current_page === 'Secret') {
            raw_data = await getDetail('general',port_id, 0);
          } else{
            console.error("Invalid current_page");
          }
          setLoading(false);
          
          console.log("raw_data: ",raw_data);
  
          if (!raw_data) {
            console.error("No data returned from getSport");
            return;
          }
          

          
  
          setData(raw_data);
        } catch (error) {
          console.error("Error in fetchData:", error);
        }
      }
  
      fetchData();
      // return () => clearInterval(interval);
    }, [rerender]);
    const handleInvest = async () => {
      console.log(localStorage.getItem('port_id'));
      console.log(localStorage.getItem('username'));
      console.log(amount);
      if (current_page === 'Secret'){
        getUsers(data.author)
      .then(f => {
        createEmail(f['email'],"Congrats your port was approved","Your Port has been approved")
        console.log(f['email']); // This will log the resolved value from the fetch call
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
      // createEmail()
      changeVis(localStorage.getItem('port_id'))
      navigate("/secret_home")
      }else{
      setLoading(true);
      const response = await investPort(localStorage.getItem('port_id'), localStorage.getItem('user_id'), amount);
      setAmount('');
      setError(response);
      setLoading(false);
      if (localStorage.getItem('Current Page') === 'Port Market'){
        localStorage.setItem('Current Page','My Ports');
      }
      }
      setRerender(rerender + 1);
    }
  
    const handleDivest = async () => {
        setLoading(true);
        const div_amount = -1 * amount;
        await investPort(localStorage.getItem('port_id'), localStorage.getItem('user_id'), div_amount);
        setLoading(false);
        setRerender(rerender + 1);
      
      
    }
    const handleRequestApproval = async () => {
      await requestApproval(localStorage.getItem('port_id'));
      setRerender(rerender + 1);
    }
    const handleApproved = async () => {
      await Approved(localStorage.getItem('port_id'));
      setRerender(rerender + 1);
      navigate("/secret_home")
    }
    const handleDenied = async () => {

      setShowModal(true);
    }
    console.log(data.author)
    return (
        <div className={styles.structure}>
            <Navbar current_page={data.name}/>

     
        {/* <Dashboard
            author ={data.author} 
            status={data.status}
            number_of_stocks={data.number_of_stocks} 
            total_pnl={data.total_pnl} 
            today_pnl={data.pnl} 
            risk={data.risk}
            max_drawdown={data.max_drawdown} 
            total_price={data.price} 
            expense_ratio={data.expense_ratio}
            description={data.description}
            start_value = {data.start_value}
            investing={true}
            handleInvest={handleInvest}
            handleDivest={handleDivest}
            amount={amount}
            setAmount={setAmount}
            handleRequestApproval={handleRequestApproval}
            handleApproved={handleApproved}
            handleDenied={handleDenied}/>  */}
       
            <Detail 
            author ={data.author} 
            status={data.status}
            number_of_stocks={data.number_of_stocks} 
            total_pnl={data.total_pnl} 
            today_pnl={data.pnl} 
            risk={data.risk}
            max_drawdown={data.max_drawdown} 
            total_price={data.price} 
            expense_ratio={data.expense_ratio}
            description={data.description}
            start_value = {data.start_value}
            investing={true}
            handleInvest={handleInvest}
            handleDivest={handleDivest}
            amount={amount}
            setAmount={setAmount}
            handleRequestApproval={handleRequestApproval}
            handleApproved={handleApproved}
            handleDenied={handleDenied}
            loading={loading}
            error={error}
            reserve={data.reserve}
             />
            <Footer />
            {showModal && <DenyModal />}
        </div>
    );
}
export default DetailPage