import React , {useState , useEffect} from 'react';
import styles from './port_draft.module.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { GiConsoleController } from 'react-icons/gi';


function ToggleButtons({position, setPosition}) {
    // console.log("position", position);
    const handlePosition = (event, newPosition) => {
        // if (newPosition !== null) {
        setPosition(newPosition);
        //   }
        
    };
  
    return (
      <ToggleButtonGroup
        size= "small"
        value={position}
        exclusive
        onChange={handlePosition}
        aria-label="postion type"
        sx={{
            borderRadius: 0, // Remove border radius from ToggleButtonGroup
          }}
        className={styles.ss_toggle}
      >
        <ToggleButton value="long" sx={{
             color: 'white',
             fontSize: '10px', // Adjust the font size to make the buttons smaller
             padding: '0px 0px', // Adjust the padding to make the buttons smaller
             minWidth: '40px',
             borderColor: 'white',
             '&.Mui-selected': {
            backgroundColor: 'white', // Change this to your desired selected color
            color: '#1B263B', // Change text color if needed
            '&:hover': {
              backgroundColor: 'white', // Change this to your desired selected color
              color: '#1B263B', // Change text color if needed
            }, 
            }
             }}>LONG</ToggleButton>
        <ToggleButton value="short" sx={{
             color: 'white', 
             fontSize: '10px', // Adjust the font size to make the buttons smaller
             padding: '0px 0px', // Adjust the padding to make the buttons smaller
             minWidth: '40px',
             borderColor: 'white',
             '&.Mui-selected': {
            backgroundColor: 'white', // Change this to your desired selected color
            color: '#1B263B', // Change text color if needed
            '&:hover': {
              backgroundColor: 'white', // Change this to your desired selected color
              color: '#1B263B', // Change text color if needed
            }, 
            }
             }}>SHORT</ToggleButton>
      </ToggleButtonGroup>
    );
  }



function SelectedStock({index, symbol, price, spercent, onClick,modified, setModified, setSelectedStocks}) {

    // console.log('percent1 ', spercent);
    const [disPercent, setDisPercent] = useState(spercent);
    // console.log('percent2 ', disPercent);
    const [position, setPosition] = useState("long");
    const handleInputChange = (e) => {
        setDisPercent(e.target.value);
        setModified(true);
        setSelectedStocks(prevSelectedStocks => {
            // Create a copy of the previous state array
            const updatedStocks = [...prevSelectedStocks];
            
            // Modify the specific element at the given index
            updatedStocks[index] = {
                ...updatedStocks[index],
                percent: e.target.value,
            };
            
            // Return the modified array to update the state
            return updatedStocks;
        });
    }



    useEffect(() => {
        setDisPercent(spercent);
        
    }, [spercent]);
    

    // useEffect(() => {
    //     console.log("hiiii0.1", spercent);
    //     console.log("123disPercent", disPercent);
    //     setSelectedStocks(prevSelectedStocks => {

    //         const updatedStocks = [...prevSelectedStocks];
            

    //         updatedStocks[index] = {
    //             ...updatedStocks[index],
    //             percent: disPercent,
    //         };
            

    //         return updatedStocks;
    //     });
    // }, [disPercent]);


    useEffect(() => {
        setSelectedStocks(prevSelectedStocks => {
            // Create a copy of the previous state array
            const updatedStocks = [...prevSelectedStocks];
            
            // Modify the specific element at the given index
            updatedStocks[index] = {
                ...updatedStocks[index],
                position: position,
            };
            
            // Return the modified array to update the state
            return updatedStocks;
        });
    }, [position]);


    return (
        <div className={styles.selected_stock}>
            <div className={styles.ss_front}>{symbol}</div>
            <div className={styles.ss_middle}>${parseFloat(price).toFixed(2)}</div>
            <ToggleButtons position={position} setPosition={setPosition}/>
            <div className={styles.ss_back}><div className={styles.percentage} ><input value={disPercent} onChange={handleInputChange} className={styles.percent_input}/>%</div></div>
            <div className={styles.ss_delete} onClick={onClick}>X</div>
        </div>
    );
}

function PortDraft({data , selectedStocks, setSelectedStocks}) {
    const [modified, setModified] = useState(false);


    useEffect(() => {
        if (selectedStocks.length === 0) {
            setModified(false);
        }
    }, [selectedStocks.length]);


    useEffect(() => {
        // console.log("hiiii1 ",selectedStocks);
        let selectedStockPrices = data.map(stock => ({
            symbol: stock.symbol,
            price: stock.price
        }));
        // console.log("hiiii2 ",selectedStockPrices);
        let selectedStockSymbols = selectedStocks.map(stock => stock.stock);
        selectedStockPrices = selectedStockPrices.filter(row => selectedStockSymbols.includes(row.symbol));
        // console.log("hiiii3 ",selectedStockPrices);
        
        let modifiedSelectedStocks = selectedStocks.map((stock, index) => ({...stock, price: selectedStockPrices[selectedStockPrices.findIndex(stockp => stockp.symbol === stock.stock)].price}));
        // setSelectedStocks(modifiedSelectedStocks);
        // console.log("hiiii4 ",modifiedSelectedStocks);
        // const newselectedStocks = data.filter(row => selectedStockPrices.includes(row.symbol));

        if (!modified) {
            // console.log("length", selectedStocks.length);
            const percentage = ((100/selectedStocks.length).toFixed(2))
            // console.log("percentage", percentage);
            const remainder = (100 - (percentage * selectedStocks.length)).toFixed(2);
            // console.log("remainder", remainder);
            modifiedSelectedStocks = modifiedSelectedStocks.map((stock, index) => ({...stock, percent: index === 0 ? (parseFloat(remainder)+parseFloat(percentage)).toFixed(2) : percentage}));
            // console.log("hiiii5.5 ",modifiedSelectedStocks);
            setSelectedStocks(modifiedSelectedStocks);
        }else{
            modifiedSelectedStocks = modifiedSelectedStocks.map(stock => ({
                ...stock,
                percent: stock.percent !== undefined ? stock.percent : ""
            }));
            setSelectedStocks(modifiedSelectedStocks);
        }
        
        // console.log("hiiii5 ",selectedStocks);
        // setFilteredData(newfilteredData);
    }, [data,selectedStocks.length]);
    const handleDropClick = (fullName) => {
        setSelectedStocks(prevSelectedStocks => prevSelectedStocks.filter(stock => stock.stock !== fullName));
    }
    

    let stock_arr = [];
    for(let i =0; i < selectedStocks.length; i++) {
        stock_arr.push(selectedStocks[i].stock);
    }
    console.log("STOCK ARR", stock_arr);
    const selected_stocks = selectedStocks.map((stock, index) => (
        // console.log("stock", stock," ",stock.percent),
        <div className='flex-item' key={index}>
            <SelectedStock index={index} symbol={stock.stock} price = {stock.price} spercent = {stock.percent} onClick={() =>  handleDropClick(stock.stock)} setModified={setModified} modified={modified} selectedStocks={selectedStocks} setSelectedStocks={setSelectedStocks}/>
        </div>
    ));
    return (
        <div className={styles.background}>
            <div className={styles.list_wrap}>
                <div className={styles.list}>
                    {selected_stocks}
                </div>
            </div>
        </div>
    );
}

export default PortDraft;