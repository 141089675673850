import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import MainGrid from './components/MainGrid';
import SideMenu from './components/SideMenu';
import { Grid, Typography, TextField, Button, TextareaAutosize, Paper } from '@mui/material';
import AppTheme from '../../shared-theme/AppTheme';
import { motion } from 'framer-motion';

// Custom styled components
const GlassBox = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: '0 8px 32px 0 #1B263B',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const AnimatedTypography = styled(motion.div)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const DashboardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundImage: 'linear-gradient(45deg, #1B263B 0%, #F0F0F5 100%)',
  minHeight: '100vh',
}));

export default function Dashboard({
  props,
  author,
  status,
  number_of_stocks,
  total_pnl,
  today_pnl,
  risk,
  max_drawdown,
  total_price,
  expense_ratio,
  start_value,
  description,
  investing,
  handleInvest,
  handleDivest,
  amount,
  setAmount,
  handleRequestApproval,
  handleApproved,
  handleDenied
}) {
  const [showWelcome, setShowWelcome] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowWelcome(false), 30);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <DashboardContainer>
        <SideMenu />
        <AppNavbar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            padding: (theme) => theme.spacing(3),
          }}
        >
          {showWelcome && (
            <AnimatedTypography
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <Typography variant="h2" align="center" color="primary.contrastText">
                Welcome to Your Dashboard
              </Typography>
            </AnimatedTypography>
          )}
          <Stack spacing={4}>
            <Header />
            <GlassBox>
              <MainGrid
                author={author}
                status={status}
                number_of_stocks={number_of_stocks}
                total_pnl={total_pnl}
                today_pnl={today_pnl}
                risk={risk}
                max_drawdown={max_drawdown}
                total_price={total_price}
                expense_ratio={expense_ratio}
                start_value={start_value}
                amount={amount}
                setAmount={setAmount}
                description={description}
                handleApproved={handleApproved}
                handleInvest={handleInvest}
                handleDivest={handleDivest}
                handleDenied={handleDenied}
                handleRequestApproval={handleRequestApproval}
              />
            </GlassBox>
          </Stack>
        </Box>
      </DashboardContainer>
    </AppTheme>
  );
}