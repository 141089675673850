import React , {useState , useEffect} from 'react';
import styles from './card.module.css';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { parseISO, parse } from 'date-fns';
import DetailPage from '../New_Detail/detail_page';
import { AuthProvider ,AuthContext } from '../Authentication';
import { BrowserRouter, Route, Routes,redirect,Navigate } from 'react-router-dom'
import PrivateRoute from '../App';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function LineGraph({ dataa, labels, index }) {
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Sample Data',
          data: dataa,
          fill: false,
          backgroundColor: '#1B263B',
          borderColor: '#1B263B', // Default border color
          pointRadius: 0,
          pointBackgroundColor: '#1B263B',
          cubicInterpolationMode: 'monotone',
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#1B263B',
          },
        },
    },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day', // Adjust the unit as needed (e.g., 'minute', 'hour', 'day', 'month')
            displayFormats: {
                day: ' ', // Format for the x-axis labels
              }, // Adjust the display format as needed (e.g., 'MMM D, YYYY')
               // Adjust the unit step size as needed
          },
          grid: {
            color: '#949494',
            borderColor: 'red',
            drawBorder: false, // Remove the border line on the right
            // borderDash: [0, 0], // Customize the grid line style
            drawOnChartArea: false, // Remove the grid lines within the chart area
            drawTicks: false, // Remove the ticks on the grid lines
            lineWidth: 2,
          },
        },
        y: {
            grid: {
                color: '#949494',
                borderColor: 'red',
                drawBorder: false, // Remove the border line on the top
                // borderDash: [0, 0], // Customize the grid line style
                drawOnChartArea: false, // Remove the grid lines within the chart area
                drawTicks: false, // Remove the ticks on the grid lines
                lineWidth: 2,

            },
            ticks:{
            display: false,
            }
        },
      }
}
    return (
      <div className={styles.graphContainer}>
        <Line data={data} options={options} className="canvas" />
      </div>
    );
  };
  
function NCard({ title, author, pnl, risk, price,id,current_page,price_hist,date_hist, description,status}) {
    const dateObjectHist = date_hist.map((timestamp) => new Date(timestamp * 1000));
    console.log("I have data ",dateObjectHist);
    console.log("I have data ",price_hist);
    const navigate = useNavigate();

    const handleClick = () => {
      localStorage.setItem('port_id', id);
      localStorage.setItem('Current Page', current_page);
      navigate(`/detail`);
    }
    const [expanded, setExpanded] = useState(false);
   
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    console.log(description)
    const displayStatus = current_page === 'My Ports' ? status : 'created';


    return (
      <Card className={expanded ? styles.cardExpanded : styles.carde}>
        <div className={styles.card} onClick={localStorage.getItem('user_id') !== null ? handleClick : null }>
            <div className={styles.author}>{` ${title}`}</div>
            <div className={styles.title}>{` ${author}`}</div>
            <div className={styles.graph}>
                <LineGraph dataa={price_hist} labels={dateObjectHist} index={0} />
            </div>
            <div className={styles.nested_container}>
            <div className={styles.label}>DAILY</div>
            <div className={styles.value}>${pnl}</div>
            <div className={styles.label}>VALUE</div>
            <div className={styles.value}>${price}</div>

            <div className={styles.label_last}>RISK</div>
            <div className={styles.value_last}>{risk}/5</div>
            </div>
  

        </div>

        <CardActions>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>Description</Typography>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
           {description.slice(0,100)}
          </Typography>

    
          <div className={styles.label_last}>{`STATUS ${displayStatus}`}</div>
        </CardContent>
      </Collapse>
        </Card>
    );
}


function CardDashboard() {
    return (
        <div className={styles.container}>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            <Card/>
            </div>
        );
}

export default NCard;