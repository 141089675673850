import React, { useState } from 'react';
import './UserSettings.css';
import { changeEmail, changePassword, changeUsername } from '../user';

const UserSettingsPage = () => {
  const [user, setUser] = useState({
    name: localStorage.getItem('username'),
    email: localStorage.getItem('email'),
  });

  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [newName, setNewName] = useState(user.name);
  const [newEmail, setNewEmail] = useState(user.email);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  // Modal management
  const openModal = (modal) => {
    if (modal === 'name') setNameModalOpen(true);
    if (modal === 'email') setEmailModalOpen(true);
    if (modal === 'password') setPasswordModalOpen(true);
  };

  const closeModal = (modal) => {
    if (modal === 'name') setNameModalOpen(false);
    if (modal === 'email') setEmailModalOpen(false);
    if (modal === 'password') setPasswordModalOpen(false);
    setNewPassword('');
    setConfirmPassword('');
  };


  const handleSave = async (field) => {
    let userId = localStorage.getItem('user_id');
    if (!userId) {
      console.log("User ID not found");
      return;
    }

    if (field === 'name') {
      setUser((prevUser) => ({ ...prevUser, name: newName }));
      const success = await changeUsername(userId, newName);
      console.log(success);
      setNameModalOpen(false);
    } else if (field === 'email') {
      setUser((prevUser) => ({ ...prevUser, email: newEmail }));
      const success = await changeEmail(userId, newEmail);
      console.log(success);
      setEmailModalOpen(false);
    } else if (field === 'password') {
      if (newPassword === confirmPassword) {
        const success = await changePassword(userId, newPassword,oldPassword);
        console.log('Password updated', success);
        setPasswordModalOpen(false);
        setNewPassword('');
        setConfirmPassword('');
      } else {
        alert('Passwords do not match');
      }
    }
  };

 
  const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h3>{title}</h3>
          {children}
          <div className="modal-buttons">
            <button onClick={onClose} className="edit-button cancel-button">
              Cancel
            </button>
            <button onClick={() => handleSave(title.toLowerCase())} className="edit-button">
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="user-settings-container">
      
    <div className="user-settings-container_e">
      <h2 className="user-settings-title">User Settings</h2>

      <div className="info-section">
        <p className="info-label">Name</p>
        <p className="info-value">{user.name}</p>
        <button onClick={() => openModal('name')} className="edit-button">Change Name</button>
      </div>

      <div className="info-section">
        <p className="info-label">Email</p>
        <p className="info-value">{user.email}</p>
        <button onClick={() => openModal('email')} className="edit-button">Change Email</button>
      </div>

      <div className="info-section">
        <p className="info-label">Password</p>
        <p className="info-value">••••••••</p>
        <button onClick={() => openModal('password')} className="edit-button">Change Password</button>
      </div>

   
      <Modal isOpen={nameModalOpen} onClose={() => closeModal('name')} title="Name">
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          className="modal-input"
        />
      </Modal>

     
      <Modal isOpen={emailModalOpen} onClose={() => closeModal('email')} title="Email">
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className="modal-input"
        />
      </Modal>

    
      <Modal isOpen={passwordModalOpen} onClose={() => closeModal('password')} title="Password">
      <input type='password' placeholder='Old Password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}   className="modal-input"   id="confirmPasswords"/>

      <input type='password' placeholder='New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)}   className="modal-input"   id="confirmPasswordss"/>
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          id="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="modal-input"
        />
      </Modal>
    </div>
    </div>
  );
};

export default UserSettingsPage;
