import React , {useState, useEffect} from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import logo from './ports_logo.png';
import { getBalance } from '../user';

function Navbar({current_page}) {


    const user_id = localStorage.getItem('user_id');
    const navigate = useNavigate();

    const [balance, setBalance] = useState(0);

    useEffect(() => {
        getBalance(localStorage.getItem('username')).then(data => {
            console.log(data);
            setBalance(data);
        });
    }, []);


    const LogOut = () => {
        console.log('Logging out');
        localStorage.clear();
        navigate('/');
    }
    const PortMarket = () => {
        localStorage.setItem('Current Page','Port Market');
        navigate('/market');
        
    }
    const MyPorts = () => {
        if (user_id === null) {
            alert('Please log in to view your ports');
            navigate('/login');
        }
        localStorage.setItem('Current Page','My Ports');
        navigate('/home');
        
    }
    const BuildPort = () => {
        // localStorage.setItem('Current Page','Build-a-Port');
        if(user_id === null){
            alert('Please log in to create a port');
            navigate('/login');
        }
        navigate('/addport');
    }
    const handleLogoClick = () => {
        if (localStorage.getItem('Current Page') === 'Secret Home') {
            localStorage.setItem('Current Page','My Ports');
            navigate('/home');
        }else{
            const username = localStorage.getItem('username');
            if(username === 'k_admin' || username === 'reilljoh' || username === 'admin1'){
            localStorage.setItem('Current Page','Secret Home');
            navigate('/secret_home');
            }
        }
        
    }   

    return (
        <div className={styles.component}>
            <img className={styles.logo} src={logo} alt='Logo' onClick={handleLogoClick} />
            <div className={styles.user_display} >{localStorage.getItem('username')}</div>
            <div className={styles.display}>{current_page}</div>
            <div className={styles.display}>${balance}</div>
            <div className={styles.tab} onClick={PortMarket}>Port Market</div>
            <div className={styles.tab} onClick={MyPorts}>My Ports</div>
            <div className={styles.tab} onClick={BuildPort}>Create Ports</div>
            <div className={styles.sign_tab}><div className={styles.sign_button} onClick={LogOut}>Sign Out</div></div>
        </div>
    );
}

export default Navbar;