import React, { useState, useEffect } from 'react';
import styles from './modal.module.css';
import { createPort, investPort } from '../user';
import { trefoil } from 'ldrs';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';

trefoil.register();

function Modal({ show, handleClose, selectedStocks, setSelectedStocks}) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [investment, setInvestment] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [portCreated, setPortCreated] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    async function handleBuildPort(e) {
        const stocks = selectedStocks.map(sstock => sstock.stock);
        const stockfmtstring = stocks.join(' ');
        const percentages = selectedStocks.map(sstock => sstock.percent);
        const percentagefmtstring = percentages.join(' ');
        const positions = selectedStocks.map(sstock => sstock.position);
        const position_typesfmtstring = positions.join(' ');
        const display_title = title.trim();
    
        if (!display_title) {
            setError('Title is required');
            return;
        }
            
    
        const display_description = description.trim();
        if (!display_description) {
            setError('Description is required');
            return;
        }
    
        const moneyValue = parseFloat(investment);
        if (isNaN(moneyValue) || moneyValue <= 5) {
            console.log("Money must be a number greater than 0.");
            setError('Money must be greater or equal to $5.');
            return;
        }
    
        const username = localStorage.getItem('username');
        const user_id = localStorage.getItem('user_id');
    
        // Start loading
        setLoading(true);
        setError('');
    
        // Assume createPort returns a promise
        const createdPort = await createPort(display_title, username, stockfmtstring, percentagefmtstring, display_description, position_typesfmtstring);
        setSelectedStocks([]);
        setPortCreated(true);
        console.log("Created portfolio:", createdPort);
        await investPort(createdPort, user_id, moneyValue)
        setLoading(false);
    }

    function handleCreationClose() {
        setPortCreated(false);
        handleClose();
        navigate('/home');

    }

    if (!show) {
        return null;
    }
    if(portCreated){
        return (
            <div className={styles.modal_overlay}>
                <div className={styles.modal}>
                    <div>
                        <div className={styles.modal_close_button} onClick={handleCreationClose}>X</div>
                        <h1>Portfolio Created</h1>
                    </div>
                </div>
            </div>
        );
        }
                        


    const percentages = selectedStocks.map(sstock => sstock.percent);
    const sumOfPercentages = percentages.reduce((acc, curr) => acc + parseFloat(curr), 0);
    if (selectedStocks.length >=5 && selectedStocks.length <= 30 && sumOfPercentages === 100){
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal}>
                <div>
                    <div className={styles.modal_close_button} onClick={handleClose}>X</div>
                </div>
                <div>
                    <input className={styles.title_input} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div>
                    <textarea className={styles.description_input} placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className={styles.money_sign}>$</div>
                    <input className={styles.investment_input} placeholder='100.00' value={investment} onChange={(e) => setInvestment(e.target.value)} />
                </div>
                <div>
                    {!loading && (<div className={styles.build_button} onClick={handleBuildPort}>Build</div>)}
                </div>
                {error != '' && <div className={styles.error}>{error}</div>}
                {loading && (
                    <div className={styles.loading}>
                        <l-trefoil
                            size="40"
                            stroke="4"
                            stroke-length="0.15"
                            bg-opacity="0.1"
                            speed="1.4"
                            color="black"
                        ></l-trefoil>
                    </div>
                )}
            </div>
        </div>
    );
}
else{
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal}>
                <div>
                    <div className={styles.modal_close_button} onClick={handleClose}>X</div>
                </div>
                <div>
                    <h1>Invalid Portfolio</h1>
                    <h3>Portfolio must have between 5 and 20 stocks</h3>
                    <h3>The Portfolio percentage must be 100</h3>
                    <h3>Each stock must have minimum of 5%</h3>
                </div>
            </div>
        </div>
    );
}
}

export default Modal;
