import React , {useState , useEffect} from 'react';
import styles from './detail_page.module.css';
import Detail from './detail';
import Navbar from '../New_Header_Navigation/navbar';
import Footer from '../New_Footer/footer';
import { getDPorts } from '../user';




function DetailPage() {

    const [data, setData] = useState([]);

    useEffect(() => {
      async function fetchData() {
          const id = localStorage.getItem('port_id');
          const sid =  localStorage.getItem('id')
          console.log(sid,id);
          const raw_data = [await getDPorts(id)];
          console.log(raw_data[0]);
          const mapped_data = {
            name: raw_data[0].title, 
            author: raw_data[0].author,
            number_of_stocks: raw_data[0].stocks.length,
            total_pnl: (raw_data[0].valueHist[raw_data[0].valueHist.length - 1] - raw_data[0].valueHist[0])/raw_data[0].valueHist[0], 
            risk: raw_data[0].curRisk, 
            price: raw_data[0].valueHist[raw_data[0].valueHist.length - 1],
            pnl: raw_data[0].valueHist.length > 1 ? ((raw_data[0].valueHist[raw_data[0].valueHist.length - 1] - raw_data[0].valueHist[raw_data[0].valueHist.length - 2])/raw_data[0].valueHist[raw_data[0].valueHist.length - 1]) : 0,
            port_id: raw_data[0].portfolioId,
            expense_ratio:raw_data[0].expenseRatio,
            price_hist: raw_data[0].pnlHist,
            date_hist: raw_data[0].dateHist,
            description: raw_data[0].description,
            owner: raw_data[0].owner,
          }
     
          setData(mapped_data);

      }
      fetchData();
    }, []);
    return (
        <div className={styles.structure}>
            <Navbar current_page={data.name}/>
            <Detail 
            author ={data.author} 
            number_of_stocks={data.number_of_stocks} 
            total_pnl={data.total_pnl} 
            today_pnl={data.pnl} 
            risk={data.risk} 
            total_price={data.price} 
            expense_ratio={data.expense_ratio}
            price_hist={data.price_hist} 
            date_hist={data.date_hist}
            description={data.description}
            owner = {data.owner}
            investing={true} />
            <Footer />
        </div>
    );
}
export default DetailPage