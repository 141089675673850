import React, { useState, useEffect } from 'react';
import styles from './modal.module.css';
import { Denied} from '../user';
import { useNavigate } from 'react-router-dom';



function DenyModal() {  
    const [reason, setReason] = useState('');
    const navigate = useNavigate();

    const handleDenied = async () => {
        await Denied(localStorage.getItem('port_id'));
        navigate("/secret_home")
      }
    
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal}>
                <div>
                    <div>Reason</div>
                    <textarea className={styles.description_input} value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                        <div className={styles.build_button} onClick={handleDenied}>Submit</div>
                </div>

           
            </div>
        </div>
    );

}

export default DenyModal;
