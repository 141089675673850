import React from "react";
import styles from './landing.module.css';
import Header from './header';
import Body from './body';
import Footer from '../New_Footer/footer';
import FinTechLandingPage from "./FinTechLandingPage";

function Landing() {
  return (
     <div className={styles.structure}>
         <div className={styles.NoSpaceWrapper}>
             <Header />
             <FinTechLandingPage />
         </div>
         {/* <Footer />  */}
     </div>
  )
}


export default Landing;