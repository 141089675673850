import React, {useState, useEffect, useMemo} from 'react';
import styles from './home.module.css';
import NCard from '../Card_Display/card';
import Navbar from '../New_Header_Navigation/navbar';
import Footer from '../New_Footer/footer';
import { getCards} from '../user';
import { parseISO, parse } from 'date-fns';
import SideMenu from '../New_Detail/dashboard/components/SideMenu';





function SortOrderButton({sortOrder, setSortOrder}) {
    const handleSortOrderToggle = () => {
        setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'des' : 'asc'));
    };

    return (
        <button className={styles.sort_value} onClick={handleSortOrderToggle}>
            {sortOrder === 'des' ? 'Descending' : 'Ascending'}
        </button>
    );
}

function DropDownButton({selectedOption, setSelectedOption}) {
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    
    return (<select className={styles.sort_value} value={selectedOption} onChange={handleOptionChange}>
                <option className={styles.option_wrap} value="Daily">Daily</option>
                <option className={styles.option_wrap} value="Value">Value</option>
                <option className={styles.option_wrap} value="Risk">Risk</option>
            </select>);
}





function CardDashboard({ current_page }) {
    console.log("Current Page: ",current_page);
    const [data, setData] = useState([]);
    const [time, setTime] = useState(Date.now());
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState('Daily');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now()); // Update state to trigger re-render
          }, 60000); // 60000 ms = 1 minute
        async function fetchData() {
            const user = localStorage.getItem('username');
            const id = parseInt(localStorage.getItem('user_id'));
            console.log("ID: ",id);
            // const current_page = localStorage.getItem('Current Page');
            let raw_data;
            if (current_page === 'My Ports') {
                raw_data = await getCards('personal',id);
            }
            else if(current_page === 'Port Market') {
                raw_data = await getCards('general',0);
            }else if(current_page === 'Secret') {
                raw_data = await getCards('pending',0);
            }
            console.log("RAW_DATA: ",raw_data);
            setData(raw_data);

        }
        fetchData();
        return () => clearInterval(interval);
    }, [time,current_page]);
    useEffect(() => {
        setFilteredData(data.filter(port =>
        port.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
    );
    }, [searchTerm, data]);
    // setSortedData(filteredData.sort((a, b) => a.name.localeCompare(b.name)))
    const sortedData = useMemo(() => {
        const sorted = [...filteredData];
        if (selectedOption === 'Daily') {
            sorted.sort((a, b) => (a.pnl - b.pnl) * (sortOrder === 'asc' ? 1 : -1));
        } else if (selectedOption === 'Value') {
            sorted.sort((a, b) => (a.price - b.price) * (sortOrder === 'asc' ? 1 : -1));
        } else if (selectedOption === 'Risk') {
            sorted.sort((a, b) => (a.risk - b.risk) * (sortOrder === 'asc' ? 1 : -1));
        }
        return sorted;
    }, [filteredData, selectedOption, sortOrder]);



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    

    console.log(sortedData);
    const gports = sortedData.map((port, index) => (
            <div className='flex-item' key={index}>
                <NCard title={port.name} author={port.author}  pnl ={port.pnl} risk={port.risk} price ={port.price} id ={port.port_id} current_page={current_page} price_hist={port.value_hist} date_hist={port.date_hist} description={port.description} status ={port.status}/>
            </div>
    ));

    
    return (<div>
        <div className={styles.searchbar_wrap}>
        <input className={styles.searchbar}
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={handleSearch}
            />
            <DropDownButton selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
            <SortOrderButton sortOrder={sortOrder} setSortOrder={setSortOrder}/>
            </div>
        <div className={styles.container}>
            {gports}
            </div>
            </div>
        );
}


function Home({ current_page }) {
    return (    
        <div className={styles.structure}>
            <Navbar current_page={current_page}/>
            {/* <SideMenu/> */}
            <CardDashboard current_page={current_page}/>
            <Footer />
        </div>
    );
}
export default Home