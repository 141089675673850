import React , {useState , useEffect} from 'react';
import styles from './page.module.css';
import { getStocks } from '../user';
import SearchResults from './searchresults';
import PortDraft from './port_draft';
import Modal from './modal';
import Header from '../New_Header_Navigation/navbar';
import { Overlay } from 'react-bootstrap';
import Footer from '../New_Footer/footer';
import SideMenu from '../New_Detail/dashboard/components/SideMenu';
function SearchBar ({searchQuery, setSearchQuery }) {
    return (
        <div className={styles.searchbar_wrap}>
            <input type="text" 
            placeholder="Enter Ticker or Company Name"
            value={searchQuery}
            className={styles.searchbar}
            onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
    );
}
function removeNulls(data) {
    if (Array.isArray(data)) {
      // Filter each element of the array
      return data
        .map(item => removeNulls(item)) // Recursively remove nulls
        .filter(item => item !== null);  // Remove null elements
    } else if (data !== null && typeof data === 'object') {
      // Filter each property of the object
      return Object.fromEntries(
        Object.entries(data)
          .map(([key, value]) => [key, removeNulls(value)]) // Recursively remove nulls
          .filter(([key, value]) => value !== null) // Remove null properties
      );
    } else {
      // Return other data types as is
      return data;
    }
  }

async function fetchData() {
    const raw_data = await getStocks();

    console.log(raw_data[0]['full_name']);
    const data_raw = removeNulls(raw_data);
    console.log("data_raw",data_raw);
    const data = data_raw.map(stock => ({ symbol: stock.symbol, percent: stock.changePercent, price: stock.price, volume: stock.volume, fullName: stock.fullName}));
    console.log("data",data);
    return data;
}




function MakeAPort() {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStocks, setSelectedStocks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => {
        setShowModal(true);
    }
        
        
        
    const handleCloseModal = () => setShowModal(false);
    useEffect(() => {
        async function loadData() {
            const fetchedData = await fetchData();
            setData(fetchedData);
        }
        loadData();
    }, []);
    // console.log("hiiii6 ",selectedStocks);
    useEffect(() => {

        // console.log("selectedStocks @ MakeAPort",selectedStocks);
    },[selectedStocks]);
    return (
        <div className={styles.structure}>
        <Header current_page={"Build-a-Port"}/>
        {/* <SideMenu/> */}
        <div className={styles.component}>
        <div className={styles.search_component}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
            <div className={styles.search_results_wrap_wrap}>
                <SearchResults searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} selectedStocks={selectedStocks} setSelectedStocks={setSelectedStocks}/>
            </div>
        </div>
        
        <PortDraft data={data} selectedStocks={selectedStocks} setSelectedStocks={setSelectedStocks}/>
        <div className={styles.wrap}>
                {selectedStocks.length > 0 && (
                    <div className={styles.modal_pop_button} onClick={handleShowModal}>NEXT</div>
                    
                )}
        </div>
        {showModal && (
                <Modal
                    show={showModal}
                    handleClose={handleCloseModal}
                    selectedStocks={selectedStocks}
                    setSelectedStocks={setSelectedStocks}
                />
            )}
        </div>
        <Footer/>
        </div>
    );
}

export default MakeAPort;