import React, { useState } from "react";
import { getUsers, createUser, deleteUser, updateUser } from '../user';
import './new_login.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {FaUser, FaLock} from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import SignUp from "./sign-up/SignUp";
const bcrypt = require('bcryptjs');



const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    // const handleUsernameChange = (e) => {
    //   setUsername(e.target.value);
    // };
  
    // const handlePasswordChange = (e) => {
    //   setPassword(e.target.value);
    // };
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        createUser(username,password,email);
        // setUsername('');
        // setPassword('');
        navigate('/login');
    //     onClose();
    };
    return (

        // <SignUp></SignUp>
        <body className='login_body'>
            <div className='login_wrapper'>
                <form action="" onSubmit={handleSubmit}>
                    <h1>Register</h1>
                    <div className='input_box'>
                        <input type="username" placeholder='Username'value={username}
                            onChange={(e) => setUsername(e.target.value)} required/>
                        <FaUser className='icon'/>
                    </div>
                    <div className='input_box'>
                        <input type="email" placeholder='Email'value={email}
                            onChange={(e) => setEmail(e.target.value)} required/>
                        <MdEmail className='icon'/>
                    </div>
                    <div className='input_box'>
                        <input type="password" placeholder='Password'value={password}
                            onChange={(e) => setPassword(e.target.value)} required/>
                        <FaLock className='icon'/>
                    </div>
                    <button type='Submit'>Register</button>
                    <div className='register_link'>
                        <p>Already have an account? <a href="#"><Link to="/login">Login</Link></a></p>
                    </div>
                    </form>
            </div>
            </body>
            
    );
    
};



export default Register;